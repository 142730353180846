/** @format */
// eslint-disable-next-line no-unused-vars
import React, { ChangeEventHandler, useState, useEffect, useRef } from "react";

import useMutationObservable from "./customHooks/DomMutation";
// INFO: bug with office.js not loading
// https://github.com/OfficeDev/office-js/issues/3734
//import * as Office from "@microsoft/office-js/dist/office";
import "./css/composecontainer.css";
import "./css/extension.css";
import "./css/outlook-overwrite.css";
import "./css/bundle.css";
import { POLLING_DELAY_MILLISECONDS } from "./utils/constants";

// eslint-disable-next-line no-undef
type AsyncResult<T> = {
  status: "Failed" | "Succeeded";
  // eslint-disable-next-line no-undef
  value: T;
  // eslint-disable-next-line no-undef
  error?: { message: string };
};

declare let Office: any;
const emailBodySelector = "lavender-emailBody";
const Extenstion: React.FC = () => {
  const [paneType, setPaneType] = useState("");
  const [emailData, setEmailData] = useState({
    body: "",
    subject: "",
    toAddress: "",
    from: "",
  });
  let intervalId: ReturnType<typeof setInterval>;

  const chromeId = localStorage.getItem("lv-chrome-id");
  useEffect(() => {
    async function setupOutlook() {
      Office.onReady(() => {
        let body: string, subject: string, toAddress: string, from: string;
        //this runs every POLLING_DELAY_MILLISECONDS. Checks for updates in Outlook and passes them to sidebar's React state.
        // Every time it runs there is noticeable freezing from asyncGet.
        async function getData() {
          const bodyPromise = createEmailBodyPromise()
            .then((OutlookBody) => (body = OutlookBody))
            .catch((e) => console.error(e));
          const subjectPromise = createEmailSubjectPromise()
            .then((OutlookSubject) => {
              subject = OutlookSubject;
            })
            .catch((e) => console.error(e));
          const addressPromise = createEmailAddressPromise()
            .then((OutlookToAddress) => (toAddress = OutlookToAddress))
            .catch((e) => console.error(e));
          from = Office?.context?.mailbox?.item?.from?.emailAddress || "";
          if (from) {
            setPaneType("thread");
          } else {
            setPaneType("compose");
          }
          await Promise.all([bodyPromise, addressPromise, subjectPromise]);
          setEmailData(() => {
            setupMobile(body);
            return {
              toAddress,
              subject,
              body,
              from,
            };
          });
        }
        getData();
        // INFO: the event is not firing from onMessageCompose on src/launchevent.js, so stuck doing this.
        // LaunchEvents is removed xml due to this issue
        intervalId = setInterval(getData, POLLING_DELAY_MILLISECONDS);
        if (chromeId) {
          Office.context.roamingSettings.set("lv-chrome-id", chromeId);
          Office.context.roamingSettings.saveAsync(function (
            asyncResult: Office.AsyncResult<void>
          ) {
            if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
              console.error(
                "Failed to save setting: " + asyncResult.error?.message
              );
            }
          });
        }
      });
    }
    setupOutlook();
    // Programmatically run /lavender/react/init.js
    // console.log("fetch and run init.js");
    // fetch("/lavender/react/init.js")
    //   .then((response) => response.text())
    //   .then((scriptContent) => {
    //     const script = document.createElement("script");
    //
    //     console.log("add script to body");
    //     // Set the script content
    //     script.textContent = scriptContent;
    //
    //     // Append the script tag to the body
    //     document.body.appendChild(script);
    //   });
    //   .catch((error) => console.error("Error loading script:", error));
    //programatically add script to header
    // const script = document.createElement("script");
    // script.src = "/lavender/react/init.js";
    // document.head.appendChild(script);]
    // console.log(window.ExtensionInit);
    // window.ExtensionInit();
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // INFO listen to email body changes from the extension to put it back to the Outlook email
  const emailBodyRef = useRef(null);
  useMutationObservable(emailBodyRef.current, onEmailMutation);

  return (
    <div
      className={`ms-welcome ${
        paneType === "compose" ? "lavender-email-container" : ""
      } ${
        paneType === "thread" ? "lavender-thread-container" : ""
      } background-outlook-native`}
    >
      <div className="lavender-extension"></div>
      <button
        style={{ position: "absolute" }}
        onClick={() => {
          //delete localstorage for chrome id
          localStorage.removeItem("lv-chrome-id");
          window.location.reload();
        }}
      >
        Logout
      </button>
      <div id="lv-email" style={{ display: "none" }}></div>
      <div
        id="lv-outlookNative-dev-tools"
        className="hidden"
        style={{
          position: "absolute",
          top: 48,
          zIndex: 10010,
          background: "white",
          padding: 8,
          maxHeight: "calc(100vh - 64px)",
          overflowY: "scroll",
        }}
      >
        <br />
        <br />
        <div className={"bold"}>Recipients:</div>
        <div style={{ border: "1px solid black" }}>
          {emailData.toAddress?.includes(",") ? (
            emailData.toAddress.split(",").map((email) => {
              return (
                <div key={email} className="lavender-email-address">
                  {email}
                </div>
              );
            })
          ) : (
            <div className="lavender-email-address" key={emailData.toAddress}>
              {emailData.toAddress}
            </div>
          )}
        </div>
        <div className={"bold"}>From:</div>
        <div style={{ border: "1px solid black" }}>
          <div className="lavender-from-email-address">{emailData.from}</div>
        </div>
        <br />
        <div>
          <p className={"bold"}>Subject</p>
          <input
            className="lavender-subject"
            type="text"
            style={{ border: "1px solid black" }}
            value={emailData.subject}
            onChange={onSubjectChange}
            //todo: subject is only one-way sync for now.
          />
        </div>
        <br />
        <p className={"bold"}>Cleaned Body:</p>
        <div
          style={{ border: "1px solid black" }}
          id={"lavender-outlook-cleaned-body"}
        ></div>
        <br />
        <p className={"bold"}>Styled Body:</p>
        <div
          style={{ border: "1px solid black" }}
          ref={emailBodyRef}
          className={emailBodySelector}
          dangerouslySetInnerHTML={{ __html: emailData.body }}
        >
          {/*{emailData.body}{" "} Uncomment to see what the html is*/}
        </div>
        <br />
        <div className={"bold"}>Raw Body:</div>
        <div style={{ border: "1px solid black" }}>{emailData.body}</div>
        <br />
        <div className={"bold"}>First Body:</div>
        <div
          style={{ border: "1px solid black" }}
          id={"lavender-outlook-first-body"}
        ></div>
        <br />
        <p className={"bold"}>Logs:</p>
        <div style={{ border: "1px solid black" }} id={"lavender-outlook-logs"}>
          {chromeId}
        </div>
      </div>
    </div>
  );
};

export default Extenstion;

// INFO: Helper functions
const setupMobile = (body: string): void => {
  const mobileBody = document.querySelector(
    "#lavender-mobile-container .lavender-email-body"
  );
  //INFO: disable mobile preview from editing the email until we solve the email body re formatting that Outlook does that breaks the email
  // with bunch of new lines
  // Mobile Preview is load async so this will insure that we will disable Mobile editing.
  mobileBody?.setAttribute("contentEditable", "false");

  if (mobileBody) {
    mobileBody.innerHTML = body;
  }
  // INFO: show message that we don't support Mobile editing
  const outlookMsgList = document.querySelectorAll(".outlook-messages");
  outlookMsgList.forEach((dom) => {
    dom.classList.add("show");
  });
};

const onSubjectChange: ChangeEventHandler<HTMLInputElement> = (e) => {
  const subject = e.target.value; //why was there a .trim() on it? - Casey
  if (subject === Office?.context?.mailbox?.item?.subject) {
    return;
  }
  Office?.context?.mailbox?.item?.subject?.setAsync(
    subject,
    {
      asyncContext: undefined,
    },
    function (asyncResult: AsyncResult<string>) {
      if (asyncResult.status == Office.AsyncResultStatus.Failed) {
        console.error(
          "ERROR: Set Subject Failed %o",
          asyncResult.error?.message
        );
      }
    }
  );
};

// INFO: this listens to dom change to the fake email body and will call Outlook Native to update under certain
// requirements define in this function
// 1. Update if user uses email templates
// 2. Update if user uses suggested sentences
const onEmailMutation: MutationCallback = (mutationArray): void => {
  setSentenceSuggestion(mutationArray);
  setNewEmailTemplate(mutationArray);
};

const setSentenceSuggestion = (mutationArray: Array<MutationRecord>): void => {
  // INFO: disable biDirectional from  Mobile preview to Outlook until we solve Outlooks re formatting the email from Mobile
  // const setTextFromMobileEditor = (mutationArray: Array<MutationRecord>): void => {
  //   const hasMobileEdits = mutationArray.find((record) => {
  //     const target = record.target as HTMLElement;
  //     return target.classList.contains("lv-paste");
  //   });
  //   if (hasMobileEdits) {
  //     const target = mutationArray[0].target as HTMLElement;
  //     target.classList.remove("lv-paste");
  //     const newEmailBody = target?.innerHTML.trim()
  //     if (newEmailBody) {
  //       setOutlookEmailBody(newEmailBody);
  //     }
  //   }
  // }
  const hasSentences = mutationArray.find((record) => {
    const target = record.target as HTMLElement;
    return target.classList.contains("lv-hl-replaced");
  });
  if (hasSentences) {
    const newEmailBody = document
      .querySelector(".lavender-emailBody")
      ?.innerHTML.trim();
    if (newEmailBody) {
      setOutlookEmailBody(newEmailBody);
    }
  }
};

const setNewEmailTemplate = (mutationArray: Array<MutationRecord>): void => {
  const newEmailBody = searchMutationsRecords(
    "div.lv-bullet-point-result-email",
    mutationArray
  );
  if (newEmailBody !== null) {
    setOutlookEmailBody(newEmailBody.innerHTML.trim());
  }
};

const searchMutationsRecords = (
  searchString: string,
  mutationArray: Array<MutationRecord>
): HTMLElement | null => {
  const element = (mutationArray[0]?.target as HTMLElement)?.querySelector(
    searchString
  );

  return element as HTMLElement;
};

const setOutlookEmailBody = (val: string) => {
  Office?.context?.mailbox?.item?.body?.setAsync(
    //Lavender HTML
    val,
    {
      coercionType: "html",
    },
    // Callback method to check that setAsync succeeded
    function (asyncResult: AsyncResult<string>) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.error("ERROR: Set Body Failed %o", asyncResult.error?.message);
      }
    }
  );
};
const createEmailBodyPromise = (): Promise<string> => {
  return new Promise<string>((resolve, rejected) => {
    whereIsReadAsyncError();
    Office?.context?.mailbox?.item?.body?.getAsync(
      "html",
      { asyncContext: "This is passed to the callback" },
      (result: AsyncResult<string>) => {
        // eslint-disable-next-line no-console
        console.log("result:", result);
        // eslint-disable-next-line no-console
        console.log("result.status:", result.status);
        devtoolsLog("GetAsync Result Status: " + result.status);
        devtoolsLog("GetAsync Result Value: " + result.value);
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const rawString: string = result.value.trim();
          //Instead handled by existing signature logic
          // const cleanSignartureString: string = removeAutoText(
          //   rawString,
          //   "_MailAutoSig"
          // );
          // const cleanReplyBodyString: string = removeAutoText(
          //   cleanSignartureString,
          //   "_MailOriginal"
          // );
          resolve(rawString);
        } else if (result.value) {
          const rawString: string = result.value.trim();
          resolve(rawString);
        } else {
          console.error("ERROR - can not read email BODY");
          rejected(result.error);
        }
      }
    );
  });
};

const createEmailSubjectPromise = () => {
  return new Promise<string>((resolve, rejected) => {
    if (
      Office?.context?.mailbox?.item?.subject?.getAsync === undefined &&
      typeof Office?.context?.mailbox?.item?.subject === "string"
    ) {
      //If is opened email
      resolve(Office?.context?.mailbox?.item?.subject);
      return;
    }
    Office?.context?.mailbox?.item?.subject?.getAsync(
      (asyncResult: AsyncResult<string>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value);
        } else {
          console.error("ERROR - can not read email SUBJECT");
          rejected(asyncResult.error);
        }
      }
    );
  });
};

const createEmailAddressPromise = () => {
  return new Promise<string>((resolve, rejected) => {
    Office?.context?.mailbox?.item?.to?.getAsync(
      (asyncResult: AsyncResult<{ emailAddress: string }[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const address = asyncResult.value.reduce((string, obj) => {
            string = string.toLowerCase();
            obj.emailAddress = obj.emailAddress.toLowerCase();
            return string + obj.emailAddress + ",";
          }, "");
          resolve(address.substring(0, address.length - 1));
        } else {
          console.error("ERROR - can not read email ADDRESS");
          rejected(asyncResult.error);
        }
      }
    );
  });
};

const whereIsReadAsyncError = () => {
  if (!Office) {
    // eslint-disable-next-line no-console
    console.log("Missing 'Office'");
    devtoolsLog("Missing 'Office'");
    return;
  }
  if (!Office.context) {
    // eslint-disable-next-line no-console
    console.log("Office:", Office);
    devtoolsLog("Missing 'Office.context'");
    return;
  }
  if (!Office.context.mailbox) {
    // eslint-disable-next-line no-console
    console.log("Office.context:", Office.context);
    devtoolsLog("Missing 'Office.context.mailbox'");
    return;
  }
  if (!Office.context.mailbox.item) {
    // eslint-disable-next-line no-console
    console.log("Office.context.mailbox:", Office.context.mailbox);
    devtoolsLog("Missing 'Office.context.mailbox.item'");
    return;
  }
  if (!Office.context.mailbox.item.body) {
    // eslint-disable-next-line no-console
    console.log("Office.context.mailbox.item:", Office.context.mailbox.item);
    devtoolsLog("Missing 'Office.context.mailbox.item.body'");
    return;
  }
  if (!Office.context.mailbox.item.body.getAsync) {
    // eslint-disable-next-line no-console
    console.log(
      "Office.context.mailbox.item.body:",
      Office.context.mailbox.item.body
    );
    devtoolsLog("Missing 'Office.context.mailbox.item.body.getAsync'");
    return;
  }
  devtoolsLog("Found 'Office.context.mailbox.item.body.getAsync'");
};

const devtoolsLog = (txt: string) => {
  const logsObj = document.getElementById("lavender-outlook-logs");
  if (!logsObj) {
    return;
  }
  logsObj.innerHTML += "<br/>" + txt;
};
